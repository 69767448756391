<template>
<div id="contactContainer">
    <div class="navbarServices" id="contactNavbarServices"></div>
    <div class="badge" :class="{badgeShow: badgeShow}"><img src="./../assets/email.png">Your message was sent.</div>
    <div class="contactContent">
      <h1 class="animated" :class="{fadeInUp: playAnimText}">{{contactHeadline}}</h1>
      <div class="mailandphone animated">
        <div class="overflow-contact" :class="{scaleYAnim: playAnimText}"><a :href="`tel:${this.phoneWithNoSpaces}`"><img src="./../assets/phone.png"><span>{{footerData.phone}}</span></a></div>
        <div class="overflow-contact" :class="{scaleYAnim: playAnimText}"><a :href="`mailto:${this.footerData.email}`"><img src="./../assets/email.png"><span>{{footerData.email}}</span></a></div>
      </div>
      <form class="contactForm contactViewForm">
        <label>Name</label>
        <input type="text" name="name" v-model="name" :maxlength="25" @keydown="nameErr = false" class="animated" :class="{'shake redText': nameErr}">
        <label>Phone</label>
        <input type="text" name="phone" v-model="phone" onKeyPress="if(this.value.length==15) return false;" @keypress="checkPhone($event)" @keydown="phoneErr = false" class="animated" :class="{'shake redText': phoneErr}">
        <label>Email</label>
        <input type="text" name="email" v-model="email" :maxlength="30" @keydown="emailErr = false" class="animated" :class="{'shake redText': emailErr}">
        <label>Message</label>
        <textarea name="message" id="" cols="30" rows="5" :maxlength="200" v-model="message" @keydown="messageErr = false" class="animated" :class="{'shake redText': messageErr}"></textarea>
        <small>{{contactDisclaimer}}</small>
        <div class="btn animated" @click="sendModalAttempt()">
          <div></div>
          <span>{{contactBtnText}}</span>
        </div>
      </form>
      <img src="./../assets/clement4.png" class="contactClementImg animated" :class="{fadeInUp: playAnimText}">
    </div>
    <footerComp/>
  </div>
</template>

<script>
import axios from "axios";

export default {
  //VueMeta
  metaInfo: {
    title: 'Contact Us ',
    meta: [
      {
        //vmid: 'description',
        name: 'description',
        content:
                'Contact Clement Labs for a quick and pain free chat. We will let you know if we can help make your Shopify a magical one.'
      }
    ]
  },
  data() {
    return {
      playAnimText: false,
      name: '',
      phone: '',
      email: '',
      message: '',
      nameErr: false,
      phoneErr: false,
      emailErr: false,
      messageErr: false,
      CMS: null,
      contactHeadline: '',
      contactDisclaimer: '',
      contactBtnText: ''
    }
  },

  watch: {
    CMS() {
      this.contactHeadline = this.CMS.contact_headline[0].text
      this.contactDisclaimer = this.CMS.contact_disclaimer[0].text
      this.contactBtnText = this.CMS.contact_page_button_text[0].text
    } 
  },

  beforeCreate() {
    // CMS DEMONSTRATION
    this.$prismic.client.getSingle('contact')
    .then(doc => {
      this.CMS = doc.data
    })
  },

  methods: {
    checkPhone(e) {
      if(!(e.code.includes('Digit') || e.code.includes('Slash') || e.code.includes('Space'))) {
        e.preventDefault()
      }
    },

    encode (data) {
      return Object.keys(data)
        .map(
          key => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`
        )
        .join("&");
    },

    sendModalAttempt() {
      if(this.formValid) {
        let form = {
          name: this.name,
          phone: this.phone,
          email: this.email,
          message: this.message
        }

        const axiosConfig = {
          header: { "Content-Type": "application/json" }
        };
        axios.post(
          "https://cloudflare-form-service.aaa-b91.workers.dev/",
          {
            ...form
          }
          ,
          axiosConfig
        );

        this.$store.state.modalOpened = false
        setTimeout(() => {
          this.$store.state.badgeShow = true
        }, 500)
        setTimeout(() => {
          this.$store.state.badgeShow = false
        }, 2500)
        this.email = ''
        this.message = ''
        this.phone = ''
        this.name = ''
      } else {
        this.nameErr = this.name == '' ? true : false
        this.phoneErr = this.phone == '' ? true : false
        this.emailErr = (this.email == '' || !/\S+@\S+\.\S+/.test(this.email)) ? true : false
        this.messageErr = this.message == '' ? true : false
      }
    },
  },

  computed: {
    formValid() {
      return (this.name !== '' && this.phone !== '' && this.email  !== '' && /\S+@\S+\.\S+/.test(this.email) && this.message !== '') ? true : false
    },

    footerData() {
        return this.$store.state.footerData
    },

    badgeShow() {
      return this.$store.state.badgeShow
    },

    phoneWithNoSpaces() {
      return this.footerData.phone.replace(new RegExp(' ', 'gi'), '')
    }
  },

  mounted() {
    document.body.style.height = "auto"
    window.scrollTo(0, 0)

    setTimeout(() => {
      this.playAnimText = true
    }, 1200)
  },

  destroyed() {
    document.body.style.height = "1000vh"
  }
}
</script>

<style lang="sass">

$color1: rgba(38, 70, 83, 1)
$color2: rgba(42, 157, 143, 1)
$color3: rgba(233, 196, 106, 1)
$color4: rgba(244, 162, 97, 1)
$color5: rgba(231, 111, 81, 1)

.contactViewForm
  width: 30% !important
  @media (max-width: 1400px)
    width: 50% !important
  margin: 50px auto !important
  .btn
    &:hover
      span
        color: $color4 !important

#contactContainer
  background-color: $color4
  width: 100vw
  position: absolute
  left: 0
  top: 0
  overflow: hidden
  #contactNavbarServices
    background-color: $color4
  .contactContent
    width: 100vw
    padding: 10vw 0 5vw 0
    @media (min-width: 770px) and (max-width: 1100px)
      padding-top: 250px
    box-sizing: border-box
    position: relative
    z-index: 100
    h1
      opacity: 0
      font-size: 40px
    .contactClementImg
      opacity: 0
      width: 300px
      height: 600px

</style>
